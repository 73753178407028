.certificates {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 106px;
  position: relative;
  min-height: 700px;
  height: 100vh;

  &__inner{
    padding-top: 111px;
  }

  .cert_paralax_background {
    position: absolute !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    pointer-events: none;

    .cert_back {
      position: absolute !important;
      padding: 0;
      margin: 0;
      right: 0;
      z-index: -1;
      -webkit-mask-image: 
      linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(45, 50, 66, 1) 20%
      );
      height: 100%;
    }
  }

  .cert_parallax_child {
    position: absolute !important;
    min-width: 250px;
    max-width: 450px;
    max-height: 100%;
    padding: 0;
    margin: 0;
    bottom: 40%;
    right: 12%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    margin-top: 0px;
    padding-top: 111px;
    max-width: 833px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 117px;

    color: #ffffff;
  }

  .description {
    padding-top: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .actions {
    padding-top: 60px;
    display: flex;
    gap: 42px;
  }
  &__footer {
    height: 300px;
    background: linear-gradient(0deg, #000000 33%, rgba(10, 17, 40, 0) 100%);
  }
}

.gifts {
  margin-bottom: 50px;
  &__head {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .title {
      position: relative;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 78px;
      color: #ffffff;
      padding-left: 384px;
    }
    .title::before {
      margin-left: 100px;
      content: "";
      display: block;
      width: 254px;
      height: 1px;
      background: #f8f8f8;
      left: 0;
      top: 50%;
      position: absolute;
    }

    .subtitle {
      max-width: 736px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: right;

      color: #ffffff;
    }
  }
  .cards {
    margin-top: 74px;
    display: flex;
    gap: 40px;
  }

  .card {
    width: 300px;
    height: 426px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(83, 43, 228, 0.2) 0px 0px 15px 5px;

    &__title {
      text-align: center;
      height: 32px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      color: #ffffff;
      border-top-left-radius: 27px;
      border-top-right-radius: 27px;
    }

    &__buy {
      text-align: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      color: #000000;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 27px;
      cursor: pointer;

      &.selected {
        color: #ffffff;
        background-color: rgba(247, 56, 62, 0.9);
      }
    }
    &__buy:hover {
      color: #ffffff;
      background-color: rgba(247, 56, 62, 0.9);
    }
  }
}

.gift {
  height: auto;
  margin-top: 100px;

  &__head {
    display: flex;
    align-items: flex-start;

    .title {
      margin-top: 90px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      color: #ffffff;
      position: relative;
      width: fit-content;
      z-index: 1;
    }
    .title::after {
      content: "";
      display: block;
      width: 254px;
      height: 1px;
      background: #f8f8f8;
      right: -284px;
      top: 50%;
      position: absolute;
    }
  }
  .basket {
    margin-top: 74px;
    display: flex;
    gap: 40px;
  }

  .card {
    width: 300px;
    height: 426px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      text-align: center;
      height: 32px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 29px;
      color: #ffffff;
      background-color: rgba(255, 0, 0, 0.75);
      border-top-left-radius: 27px;
      border-top-right-radius: 27px;
    }
  }
}

.vouchers {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  margin: 100px 0 0 0;
  gap: 32px;

  .voucher {
    aspect-ratio: 7/4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(30, 34, 46);
    border-radius: 2vw;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(204, 32, 39, 0);
    box-shadow: rgba(39, 67, 121, 0.4) 0px 0px 15px 5px;
    padding: 30px;

    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 20px;
    line-height: 29px;
    text-transform: uppercase;
    color: #ffffff;

    &:hover {
      background-color: rgb(30, 34, 46);
      border-color: white;
      cursor: pointer;
    }

    &.selected {
      background-color: rgb(30, 34, 46);
      border-color: white;
      cursor: pointer;
    }

    .title {
      align-self: center;
      line-height: inherit;
      width: max-content;
      margin-right: 100px;
      margin-top: 0px;
      font-size: 21px;
    }

    .whole_day {
      font-size: 12px;
      font-weight: 200;
      line-height: normal;
    }

    .upToPeopleTitle {
      margin-top: 10px;
      &__upto {
        font-size: 14px;
        line-height: 14px;
      }

      &__people {
        line-height: 24px;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .cert_price {
      color: rgb(204, 32, 39);
      font-size: 24px;
      align-self: flex-end;
      font-weight: bold;
    }
  }
}

.form_body {
  width: 70%;
  display: flex;
  justify-content: center;
  align-self: center;
}


@media only screen and (min-width : 320px) {
  .certificates {
    min-height: unset;
    height: 100%;
    
    .cert_parallax_child {
      display: none;
    }
    .cert_paralax_background .cert_back {
      right: -450px;
    }
  }

  .gifts {
      &__head {
      .title {
        font-size: 32px;
        line-height: 39px;
        padding-left: 0;
        align-self: center;
        text-align: center;
      }
      .title::before {
        content: "";
        display: none;
      }
    }
    .cards {
      flex-direction: column;
      align-items: center;
    }
  }

  .gift__head{
    flex-direction: column;
    .title {
      font-size: 32px;
      line-height: 39px;
      align-self: center;
      text-align: center;
    }
    .title::after {
      content: "";
      display: none;
    } 
  }

  .vouchers {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    margin: 100px 0 0 0;
    gap: 16px;

    .voucher{
      padding: 10px;
      .title {
        font-size: 12px;
        margin-right: 20px;
      }
  
      .whole_day {
        font-size: 8px;
      }
  
      .upToPeopleTitle {
        &__upto {
          font-size: 9px;
          line-height: 12px;
        }
  
        &__people {
          line-height: 10px;
          font-size: 12px;
        }
      }

      .cert_price {
        font-size: 12px;
      }
    }
  }
  .form_body {
    width: 90%;
  }
}
@media only screen and (min-width : 1200px) { 
  .certificates {
    min-height: 700px;
    height: 100vh;

    .cert_parallax_child {
      display: block;
    }
    .cert_paralax_background .cert_back {
      right: 0;
    }
  }

  .gifts {
    &__head {
      .title {
        font-size: 64px;
        line-height: 78px;
        padding-left: 384px;
        align-self: unset;
        text-align: unset;
      }
      .title::before {
        content: "";
        display: block;
      }
    }

    .cards {
      flex-direction: unset;
      align-items: unset;
    }
  }

  .gift__head{
    flex-direction: unset;
    .title {
      font-size: 48px;
      line-height: 59px;
      align-self: unset;
      text-align: unset;
    } 
    .title::after {
      content: "";
      display: block;
    }
  }

  .vouchers {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    margin: 100px 0 0 0;
    gap: 32px;

    .voucher{
      padding: 30px;
      .title {
        font-size: 21px;
        margin-right: 100px;
      }
  
      .whole_day {
        font-size: 12px;
      }
  
      .upToPeopleTitle {
        &__upto {
          font-size: 14px;
          line-height: 14px;
        }
  
        &__people {
          line-height: 24px;
          font-size: 24px;
        }
      }

      .cert_price {
        font-size: 24px;
      }
    }
  }

  .form_body {
    width: 70%;
  }
}

