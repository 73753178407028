.package {
    height: 400px;
    width: 250px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgb(30, 34, 46);
    border-radius: 2vw;
    border-style: solid;
    border-width: 2px;
    padding: 16px;
    border-color: rgba(204, 32, 39, 0);
    box-shadow: rgb(39 67 121 / 40%) 0px 0px 15px 5px;
    font-family: "Montserrat";
    color: #ffffff;

    &__price {
        width: 100%;
        padding: 8px 0;
        font-weight: 700;
        text-align: center;
        font-size: 22px;
        color: rgb(204, 32, 39);
    }

    &__inner {
        padding: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100%);

        &--hours {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
        }
        &--small {
            // color: rgb(89, 0, 255);
            font-size: 12px;
            text-align: center;
        }
        &--people {
            // color: rgb(89, 0, 255);
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }
        &--description {
            font-size: 14px;
            margin-top: 10px;
            padding-left: 16px;
        }

    }

    &__additionaly {
        &--title {
            margin: 10px;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
        }

        &--ul {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
        }
    }

    &.selected {
        border: #fff solid 2px;
    }
}

@media only screen and (min-width : 320px) {
    .package {
        width: auto;
        height: auto;
        justify-content: unset;
        padding: 10px;

        &__price {
            width: 100%;
            padding: 8px 0;
            font-weight: 700;
            text-align: center;
            font-size: 14px;
            color: rgb(204, 32, 39);
        }
        &__inner {
            padding: 4px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100%);

        &--hours {
            font-size: 10px;
            font-weight: 700;
            text-align: center;
        }
        &--small {
            font-size: 6px;
            text-align: center;
        }
        &--people {
            // color: rgb(89, 0, 255);
            font-size: 10px;
            text-align: center;
            text-transform: uppercase;
        }
        &--description {
            font-size: 8px;
            margin-top: 10px;
        }

        }

        &__additionaly {
            &--title {
                margin: 10px;
                font-size: 8px;
                font-weight: 700;
                text-align: center;
            }

            &--ul {
                display: flex;
                justify-content: space-between;
                font-size: 8px;
            }
        }
    }
}

@media only screen and (min-width : 1200px) { 
    .package {
        height: 400px;
        width: 250px;
        justify-content: space-evenly;
        padding: 16px;
        &__price {
            width: 100%;
            padding: 8px 0;
            font-weight: 700;
            text-align: center;
            font-size: 22px;
            color: rgb(204, 32, 39);
        }
        &__inner {
            padding: 4px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100%);

        &--hours {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
        }
        &--small {
     // color: rgb(89, 0, 255);
            font-size: 12px;
            text-align: center;
        }
        &--people {
            // color: rgb(89, 0, 255);
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
        }
        &--description {
            font-size: 14px;
            margin-top: 10px;
        }

        }

        &__additionaly {
            &--title {
                margin: 10px;
                font-size: 14px;
                font-weight: 700;
                text-align: center;
            }

            &--ul {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
            }
        }
    }
}