.club {
  .cyber {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(../assets/img/discover/cyber.webp) center no-repeat;
    background-size: cover;
    position: relative;
    min-height: 700px;
    height: 100vh;

    &__inner {
      padding-top: 106px;
      margin-top: 111px;
    }

    &__cover {
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(45, 50, 66, 0.5) 20%,
        rgba(45, 50, 66, 0.3) 100%
      );
    }

    .title {
      margin-top: 0;
      max-width: 833px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 96px;
      line-height: 117px;

      color: #ffffff;
    }

    .desc {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }

    button {
      margin-top: 32px;
      margin-bottom: 32px;
      display: block;
    }

    small {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .infomation {
    margin-bottom: 64px;
    &__inner {

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
    .discover {
      margin-top: 64px;
      &__title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }
      &__grid {
        margin: 100px auto;
        display: grid;
        max-width: 950px;
        grid-template-columns: 400px 400px;
        grid-template-rows: auto;
        gap: 100px 200px;
      }
      &__item {
        position: relative;
        min-height: 400px;
        text-align: center;

        &--title {
          text-align: center;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 21px;
          color: #ffffff;
        }

        &--starting {
          position: absolute;
          bottom: 32px;
          right: 0;
          text-align: right;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #ffffff;
        }

        .btn-primary:hover {
          background: #fff;
          color: #cd2027 !important;
        }
      }
      video {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .zone {
    min-height: 600px;
    background: url(../assets/img/discover/lounge.webp) center no-repeat;
    background-size: cover;
    &__inner {
    }

    &__cover {
      min-height: 900px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 20%,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    &__left {
      min-width: 350px;
    }

    .title {
      margin-top: 0;
      max-width: 833px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 117px;

      color: #ffffff;
    }

    .desc {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
    small {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .passes {
    &__inner {
    }

    .desc {
      padding-top: 32px;
      text-align: center;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 24px;
      color: #ffffff;
      padding-bottom: 64px;
    }
    img {
      max-width: 200px;
      max-height: 200px;
    }
    .partners {
      display: flex;
      justify-content: space-around;
      margin: 100px auto;
    }
    &__list {
      margin: 0 auto;
      display: flex;
      // grid-template-columns: repeat(5, 200px);
      // grid-template-rows: auto;
      justify-content: space-between;
      gap: 32px;
    }
    .pass {
      cursor: pointer;
      height: 250px;
      width: 250px;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: rgb(30, 34, 46);
      border-radius: 2vw;
      border-style: solid;
      border-width: 2px;
      padding: 16px;
      border-color: rgba(204, 32, 39, 0);
      box-shadow: rgb(39 67 121 / 40%) 0px 0px 15px 5px;
      font-family: "Montserrat";
      color: #ffffff;

      &__price {
        width: 100%;
        padding: 8px 0;
        font-weight: 700;
        text-align: center;
        font-size: 22px;
        color: rgb(204, 32, 39);
      }

      &__inner {
        padding: 64px 4px 4px 4px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        height: calc(100% - 48px);

        &--hours {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        }
        &--small {
          // color: rgb(89, 0, 255);
          font-size: 12px;
          text-align: center;
        }
        &--people {
          // color: rgb(89, 0, 255);
          font-size: 16px;
          text-align: center;
          text-transform: uppercase;
        }
        &--description {
          font-size: 14px;
          margin-top: 10px;
        }
      }

      &__additionaly {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        &--title {
          margin: 10px;
          font-size: 21px;
          font-weight: 700;
          text-align: center;
        }

        &--ul {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }
      }

      &.selected {
        
      }

      &__form {
        display: flex;
        justify-content: center;
      }
    }
    .pass:hover {
      border: #fff solid 2px;
    }

    &__form {
      display: flex;
      justify-content: center;
    }
  }

  .stripe {
    margin: auto 10px;
    max-height: 700px;
    max-width: 800px;
    background-color: #090c14;
    padding: 16px;
    border-radius: 1vw;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(204, 32, 39, 0);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px 5px;
  }

  @media only screen and (min-width: 320px) {
    .package {
      width: auto;
      height: auto;
      justify-content: unset;
      padding: 10px;

      &__price {
        width: 100%;
        padding: 8px 0;
        font-weight: 700;
        text-align: center;
        font-size: 14px;
        color: rgb(204, 32, 39);
      }
      &__inner {
        padding: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 48px);

        &--hours {
          font-size: 10px;
          font-weight: 700;
          text-align: center;
        }
        &--small {
          font-size: 6px;
          text-align: center;
        }
        &--people {
          // color: rgb(89, 0, 255);
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
        }
        &--description {
          font-size: 8px;
          margin-top: 10px;
        }
      }

      &__additionaly {
        &--title {
          margin: 10px;
          font-size: 8px;
          font-weight: 700;
          text-align: center;
        }

        &--ul {
          display: flex;
          justify-content: space-between;
          font-size: 8px;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .package {
      height: 400px;
      width: 250px;
      justify-content: space-evenly;
      padding: 16px;
      &__price {
        width: 100%;
        padding: 8px 0;
        font-weight: 700;
        text-align: center;
        font-size: 22px;
        color: rgb(204, 32, 39);
      }
      &__inner {
        padding: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 48px);

        &--hours {
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        }
        &--small {
          // color: rgb(89, 0, 255);
          font-size: 12px;
          text-align: center;
        }
        &--people {
          // color: rgb(89, 0, 255);
          font-size: 16px;
          text-align: center;
          text-transform: uppercase;
        }
        &--description {
          font-size: 14px;
          margin-top: 10px;
        }
      }

      &__additionaly {
        &--title {
          margin: 10px;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
        }

        &--ul {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .infomation {
      .discover__grid {
        max-width: 400px;
        grid-template-columns: auto;
        grid-template-rows: auto;
        gap: 40px;
      }
    }
    .passes {
      &__list {
        flex-wrap: wrap;
        max-width: 500px;
        gap: 32px;
        justify-content: center;
      }
      &__form {
        top: 64px;
      }
      img {
        max-width: 130px;
        max-height: 200px;
      }
      .pass {
        height: 150px;
        width: 150px;
        justify-content: flex-start;

        &__inner {
          padding: 4px;
          height: 80px;
        }
      }
      .partners {
        margin: 40px 64px;
        max-width: 500px;
        display: grid;
        grid-template-columns: auto auto;
        flex-wrap: wrap;
        gap: 32px;
      }
    }
    .zone,
    .zone__cover {
      min-height: 600px;
    }
  }

  @media only screen and (max-width: 400px) {
    .cyber {
      &__inner {
        padding-top: 64px;
      }
      .secondary-title,
      .title {
        font-size: 32px;
      }
    }
    .infomation {
      .discover {
        video {
          width: 75%;
        }
        .discover__item--starting {
          position: relative;
          margin: 0 auto;
          width: fit-content;
        }
      }
    }
    .zone,
    .zone__cover {
      min-height: 500px;
    }

    .section2__head .title{
      font-size: 32px;
    }
  }
}
