.booking_cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
        180deg,
        rgb(0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 100%,
    );
}

.booking_main {
    overflow: hidden;
    width: 100%;
    height: 1200px;
}

.booking_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.booking_iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1000px;
}

.booking_footer {
    width: 100%;
    height: 100px;
    left: 0;
    background: linear-gradient(
        180deg,
        rgb(33, 33, 33, 1) 0%,
        rgba(33, 33, 33, 0) 100%,
    );
}

@media only screen and (min-width : 320px) {
    .booking_main {
        width: unset;
        height: unset;
    }
}
@media only screen and (min-width : 1200px) { 
    .booking_main {
        width: 100%;
        height: 1200px;
    }
}