* {
  padding: 0;
  margin: 0;
  text-decoration: none;
}
#root {
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

body {
  background: #000000;
  overflow-x: hidden; 
}

.warning {
  padding: 20px 0;
  background-color: rgb(255, 157, 0);
  font-size: 24px;
  height: fit-content;
  color: #000;
}

.example {
  height: calc(100vh - 300px);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
}

.main-title {
  margin-top: 0;
  max-width: 833px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 117px;
  position: relative;
  color: #ffffff;
  width: fit-content;
  z-index: 1;
}

.secondary-title {
  margin-top: 90px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: #ffffff;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
}

.close-button {
  display: flex;
  justify-content: flex-end;
}

.close-icon {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 4px;
  cursor: pointer;
}

.btn,
button {
  border: none;
  background: #cd2027;
  border-radius: 37px;
  padding: 16px 18px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: 300ms;
}

.btn-primary {
  background: #cd2027;
  color: #ffffff;
  transition: 300ms;
}

.btn-secondary {
  background: #ffffff;
  color: #2d3242 !important;
}

.btn-halloween {
  background: #F24726;
  color: #fff !important;
}

.secondary-title.after::after {
  content: "";
  display: block;
  width: 17vw;
  height: 1px;
  background: #f8f8f8;
  right: -20vw;
  top: 50%;
  position: absolute;
}

.secondary-title.before {
  padding-left: 384px;
}

.secondary-title.before::before {
  margin-left: 100px;
  content: "";
  display: block;
  width:17vw;
  height: 1px;
  background: #f8f8f8;
  left: 0;
  top: 50%;
  position: absolute;
}

.thanks {
  .thank {
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    justify-content: center;
    font-weight: 600;
    text-align: center;
    font-size: 32px;
    color: #ffffff;
  }
}

@media only screen and (min-width : 320px) {
  .container {
    margin: 0 auto;
    width: 90%;
    max-width: 90%;
  }

  .btn,
  button {
    border-radius: 28px;
    padding: 16px 16px;
    font-size: 12px;
    line-height: 20px;
  }

  .main-title {
    font-size: 40px;
    line-height: 50px;
  }
  
  .secondary-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
  }

}

@media only screen and (min-width : 1200px) {  
  .btn,
  button {
    border-radius: 37px;
    padding: 16px 18px;
    font-size: 14px;
    line-height: 24px;
  }

  .main-title {
    font-size: 96px;
    line-height: 117px;
  }
  
  .secondary-title {
    margin-top: 90px;
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 59px;
  }
}

@media only screen and (min-width : 1440px) {  
  .container {
    margin: 0 auto;
    width: 75%;
    max-width: 75%;
  }
}
