.cz_paralax {
  position: absolute !important;
  width: 80%;
  height: 80%;
  right: 10%;
  bottom: 10%;
}

.hallo_paralax {
  position: absolute !important;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 30px;
}

.experiences {
  min-height: 100vh;

  .main_background {
    position: absolute;
    top: 0;
    left: 0;
    height: fit-content;
    margin: 0;
    -webkit-mask-image: linear-gradient(
      180deg,
      rgba(57, 104, 131, 1) 65%,
      rgba(45, 50, 66, 0) 90%
    );

    img {
      height: 100%;
    }
  }

  .main_background > img {
    height: auto;
  }
  

  .secondary_background {
    position: absolute;
    top: 0;
    left: 0;
    height: fit-content;
    margin: 0;
    z-index: -1;
    -webkit-mask-image: linear-gradient(
      180deg,
      rgba(45, 50, 66, 0) 0%,
      rgba(57, 104, 131, 1) 50%,
      rgba(57, 104, 131, 1) 75%,
      rgba(45, 50, 66, 0) 95%
    );

    img {
      height: auto;
    }
  }

  .main {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    position: relative;
    min-height: 700px;
    height: 100%;
    background: unset;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &__inner {
      padding-top: 156px;
      display: flex;
      justify-content: space-between;

      .games {
        li {
          padding-top: 16px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      .title {
        margin-top: 0;
        max-width: 833px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 96px;
        line-height: 117px;
        z-index: 0;

        color: #ffffff;
      }

      .description {
        display: flex;
        flex-direction: row;
        padding-top: 16px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;

        img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      .actions {
        padding-top: 60px;
        display: flex;
        gap: 42px;
      }
    }
  }
  
  

  .trailer {
    overflow-x: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(45, 50, 66, 0.9);
    z-index: 11;
    
    iframe {
      align-self: center;
      border: none;
    }
  }

  .experience {
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: -1;
    
    .container {
      height: 100%;
    }

    .container::before {
      content: "";
      width: 100%;
      height: inherit;
      left: 0;
      position: absolute;
      pointer-events: none;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(57, 104, 131, 0.5) 50%,
        rgba(57, 104, 131, 0.2) 75%,
        rgba(0, 0, 0, 0) 95%
      );
    }

    &__inner {
      z-index: 1;
      padding-top: 180px;
      padding-bottom: 206px;

      .title {
        max-width: 800px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 117px;
        text-transform: uppercase;

        color: #ffffff;
      }

      .description {
        position: relative;
        max-width: 600px;
        padding-top: 16px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
      }
    }
  }

  .intro {
    margin-top: 100px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(57, 104, 131, 0.5) 50%,
        rgba(57, 104, 131, 0.5) 75%,
        rgba(0, 0, 0, 1) 95%
      );

    &__inner {
      padding-top: 56px;
      display: flex;
      justify-content: center;
    }

    &__left {
      z-index: 1;
      position: absolute;
      left: 50px;
      height: 100%;
      img {
        height: 900px;
        -webkit-filter: drop-shadow(5px 15px 10px rgba(45, 50, 66, 1));
        filter: drop-shadow(5px 15px 10px rgba(45, 50, 66, 1));
      }
    }

    &__right{
      position: absolute;
      z-index: 1;
      margin-top: 600px;
      right: -100px;
      height: 100%;
      img {
        height: 500px;
        -webkit-filter: drop-shadow(5px 50px 20px rgba(27, 30, 41, 1));
        filter: drop-shadow(5px 50px 20px rgb(27, 30, 41));
      }
    }

    .grid {
      display: grid;
      align-content: center;
      gap: 20px;
      grid-template-columns: 700px 700px;
      grid-template-rows: 500px 500px;

      &__element {
        background-color: #fff;
        width: 700px;
        height: 500px;
      }

      &__element:nth-child(n+3):nth-child(-n+3) {
        width: 1420px;
        height: 800px;
      }
    }
  }

  .booking {
    display: flex;

    &__cover {
      height: 300px;
      width: 100%;
      margin-top: 430px;
      background: radial-gradient(ellipse at center,
        rgba(57, 104, 131, 0.9) 0%,
        rgba(57, 104, 131, 0.3) 40%,
        transparent 75%);
      position: absolute;
      z-index: -1;
      transition: 200ms;
    }

    &__inner {
      padding-top: 300px;
      padding-bottom: 50px;
      display: grid;
      justify-content: center;
      flex-direction: column;

      div {
        display: flex;
        justify-content: center;
      }
      .btn {
        margin-top: 100px;
        font-size: 26px;
        font-weight: normal;
        padding: 26px;
        box-shadow: rgba(67, 100, 160, 0) 0px 0px 15px 5px;
        transition: 200ms;
      }

      .btn:hover {
        translate: 0 -5px;
        background-color: rgb(216, 6, 6);
        box-shadow: rgba(185, 68, 68, 0.5) 0px 0px 15px 15px;
      }
    }
  }

  .tranding {

    &__inner {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .games {
        display: flex;
        gap: 16px;
      }

    }
  }
}

@media only screen and (max-width : 1199px) {
  .experiences {
    .main {
      min-height: unset;
      height: 100%;
    }
    .experience{
      height: 100% !important;
      margin-top: 100px;

      &__inner {
        height: 100%;
        padding-bottom: 0;
        .title {
          font-size: 28px;
          line-height: 50px;
        }
        .description {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .intro{
      .grid {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .grid__element {
        background-color: #fff;
        width: 90vw;
        height: auto;
      }
      .grid__element:nth-child(n+3):nth-child(-n+3) {
        width: 90vw;
        height: auto;
      }
    } 
    .booking__inner {
      padding-top: 100px;
    }
    .booking__cover {
      height: 300px;
      margin-top: 230px;
    }
    .intro__left {
      display: none;
    }
    .intro__right {
      display: none;
    }

    .secondary_background {
      img {
            height: auto;
            margin-top: 100px;
            -webkit-mask-image: linear-gradient(
              180deg,
              rgba(57, 104, 131, 0) 0%,
              rgba(45, 50, 66, 1) 20%
            );
          }
    }
  }
}
@media only screen and (min-width : 1200px) {  
  .experiences {
    .main {
      min-height: 700px;
      height: 100vh;
    }
    .experience {
      margin-top: 0;

      &__inner {
        height: unset;
        padding-bottom: 206px;
        .title {
          font-size: 56px;
          line-height: 117px;
        }
        .description {
          font-size: 20px;
          line-height: 36px;
        }
      }
    }
    .intro{
      .grid {
        display: grid;
        align-items: unset;
      }
      .grid__element {
        background-color: #fff;
        width: 700px;
        height: 500px;
        aspect-ratio: 2/1;
      }
      .grid__element:nth-child(n+3):nth-child(-n+3) {
        width: 1420px;
        height: 800px;
      }
    } 
    .booking__inner {
      padding-top: 300px;
    }
    .booking__cover {
      height: 300px;
      margin-top: 430px;
    }
    .intro__left {
      display: block;
    }
    .intro__right {
      display: block;
    }

    .secondary_background {
      img {
            height: auto;
            margin-top: 0;
            -webkit-mask-image: unset;
          }
    }
  }
}