.paralax_background {
  position: absolute !important;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  pointer-events: none;
}

.paralax_background::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 1) 100%
  );
}

.paralax_back {
  position: absolute !important;
  padding: 0;
  margin: 0;
  right: 0;
  z-index: -1;
}

.paralax_child1 {
  position: absolute !important;
  min-width: 1280px;
  max-width: 1920px;
  max-height: 100%;
  padding: 0;
  margin: 0;
  bottom: -40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.paralax_child2 {
  position: absolute !important;
  min-width: 1280px;
  max-width: 1920px;
  max-height: 100%;
  padding: 0;
  margin: 0;
  bottom: -40px;
  right: -40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.paralax_child3 {
  position: absolute !important;
  min-width: 1280px;
  max-width: 1920px;
  max-height: 100%;
  padding: 0;
  margin: 0;
  bottom: -40px;
  left: -40px;

  img {
    width: 100%;
    height: 100%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 106px;
  position: relative;
  min-height: 700px;
  height: 100vh;
  background: url("../assets/img/parallax/background_base.webp");
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    padding-top: 111px;
  }

  .title {
    margin-top: 0;
    max-width: 833px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 117px;

    color: #ffffff;
  }

  .description {
    padding-top: 16px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .actions {
    padding-top: 60px;
    display: flex;
    gap: 42px;
  }
  // &__footer {
  //   height: 300px;
  //   background: linear-gradient(0deg, #2d3242 33%, rgba(10, 17, 40, 0) 100%);
  // }
}

.section2 {
  width: 100%;
  
  ::-webkit-scrollbar {
    height: 5px;
    width: 10px;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(82, 112, 129, 0.2);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(82, 114, 133, 0.8);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(72, 112, 134);
  }

  margin: 100px 0 0 0;
  background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 20%,
        rgba(57, 104, 131, 0.5) 75%,
        rgba(0, 0, 0, 1) 100%
      );

  &__inner {
    display: flex;
    flex-direction: column;
  }

  .arrow-right {
    position: absolute;
    align-self: center;
    right: 13%;
    height: 50px;
  }

  .arrow-left {
    position: absolute;
    align-self: center;
    left: 13%;
    height: 50px;
  }

  &__head {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    
    .title {
      position: relative;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 78px;
      color: #ffffff;
      padding-left: 384px;
    }
    .title::before {
      margin-left: 100px;
      content: "";
      display: block;
      width: 254px;
      height: 1px;
      background: #f8f8f8;
      left: 0;
      top: 50%;
      position: absolute;
    }

    .subtitle {
      max-width: 736px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: right;

      color: #ffffff;
    }
  }
  .cards {
    scroll-behavior: smooth;
    width: 100%;
    box-sizing: border-box;
    align-self: center;
    margin-top: 40px;
    padding: 40px 80px 40px 80px;
    display: flex;
    gap: 40px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-mask-image: linear-gradient(
      90deg,
      rgba(45, 50, 66, 0) 0%,
      rgba(45, 50, 66, 1) 10%,
      rgba(45, 50, 66, 1) 90%,
      rgba(45, 50, 66, 0) 100%
    );
  }

  .card {
    flex: 0 0 300px;
    height: 426px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px 5px;
    overflow: hidden;

    &__video {
      position: absolute;
      width: 300px;
      height: 426px;

      .videoElement {
        height: 100%;
        opacity: 0;
        transition: 300ms;
      }
    }

    &__title {
      height: 100px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 140px;
      padding-left: 24px;
      color: #ffffff;
      background: linear-gradient(
          180deg,
          rgba(22, 40, 51, 0) 0%,
          rgba(14, 31, 48, 0.9) 50%,
          rgba(14, 31, 48, 1) 100%
        );
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }
}

.info {
  // background-image: url("../assets/img/info.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  
  .bubbles {
    font-size: 20px;
    border-radius: 40px;
    padding: 26px 46px;
  }

  .infographics{
    display: flex;
    flex-direction: column;
    width: 50%;
    &__left {
      display: flex;
      justify-content: left;
      margin: calc(4vw ) 0;
      z-index: 1;
    }
    &__right {
      display: flex;
      justify-content: right;
      z-index: 1;
    }
    #a { transition: 300ms; }
    #a:hover {
      scale: 1.1;
    }
    #b { transition: 300ms; }
    #b:hover {
      scale: 1.1;
    }
    #c { transition: 300ms; }
    #c:hover {
      scale: 1.1;
    }
    #d { transition: 300ms; }
    #d:hover {
      scale: 1.1;
    }
    #e { transition: 300ms; }
    #e:hover {
      scale: 1.1;
    }
  }
  &__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(40, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .vid_container {
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .background-video{
    width: 100%;
    max-height: 100%;
  }

  .main-video {
    width: 100%;
    height: 100%;
  }

  .background-video::after {
    content: "";
    width: 100%;
    aspect-ratio: 16/9;
    position: absolute;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.2) 15%,
      rgba(0, 0, 0, 0.2) 85%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  li {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    padding-left: 35px;
    list-style: none;
    background-image: url("../assets/img/decoration/check.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 30px;

    color: #ffffff;
  }
}

.book {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(57, 104, 131, 0.9) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  margin-bottom: 278px;

  &__inner {
    height: 500px;
  }
  .title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 78px;
    position: relative;
    color: #ffffff;
    width: fit-content;
  }
  .title::after {
    content: "";
    margin-left: 284px;
    display: block;
    width: 254px;
    height: 1px;
    background: #f8f8f8;
    right: -284px;
    top: 50%;
    position: absolute;
  }
  .games {
    padding: 40px;
    display: flex;
    gap: 30px;
    overflow: overlay;
  }
  .game {
    display: flex;
    flex-direction: column;

    &__title {
      margin-top: 10px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }

    &__subtitle {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 29px;

      color: #ffffff;
    }

    &__card {
      img {
        background: #d9d9d9;
        border: 1px solid #000000;
        border-radius: 27px;
      }
    }
  }
}

.footer {
  margin-top: 100px;
  padding-bottom: 100px;

  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    width: 100%;
    max-width: 300px;
  }
  &__desc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    padding-top: 10px;
    color: #69718a;
  }
  &__items {
    width: fit-content;
    display: flex;
    gap: 80px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      margin: 0;
      line-height: unset;
      text-transform: uppercase;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      position: relative;
      color: #ffffff;
    }

    .nav {
      display: flex;
      flex-direction: column;
      gap: 14px;

      &__link {
        font-family: "Montserrat";
        font-style: normal;
        font-size: 14px;
        position: relative;
        color: #69718a;
        text-decoration: underline 1px;
        text-decoration-color: transparent;
        transition: 300ms;
      }

      &__link:hover {
        text-decoration-color: #69718a;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width : 320px) {
  .section2__head {
    .title {
      font-size: 32px;
      line-height: 39px;
      padding-left: 0;
      align-self: center;
      text-align: center;
    }
    .title::before {
      content: "";
      display: none;
    }
  }

  .actions {
    flex-direction: column;
  }

  .section2 {
    margin-top: 50px;
    .cards {
      padding: 0px 50px 40px 60px;
    }
    .card {
      flex: 0 0 250px;
      height: 350px;
      border-radius: 27px;

      &__video {
        position: absolute;
        width: 250px;
        height: 350px
      }
    }
  }

  .info {
    li {
        font-size: 16px;
        line-height: 25px;
        background-size: 20px;
        padding-left: 22px;
    }

    &__inner {
      position: relative;
      justify-content: flex-start;
      aspect-ratio: unset;
      margin-bottom: 0;
      margin-top: 0;
    }
    .bubbles {
      font-size: 12px;
      border-radius: 28px;
      padding: 16px 16px;
    }
    .infographics {
      width: 90%;
      gap: 30px;
    }
    .background-video::after {
      content: "";
      background: linear-gradient(
        180deg, 
        rgb(0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 15%,
        rgba(0, 0, 0, 0) 85%, 
        rgb(0, 0, 0) 100%);
    }
    .vid_container {
      margin-top: 0;
    }
  }

  .footer {
    &__inner {
      flex-direction: column-reverse;
    }
    &__items {
      display: grid;
      grid-template-columns: auto auto;
      align-self: center;
      margin: 0 0 50px 0;
    }
    &__info {
      align-self: center;
    }
  }
}

@media only screen and (min-width : 1200px) { 
  .section2__head {
    .title {
      font-size: 64px;
      line-height: 78px;
      padding-left: 384px;
      align-self: unset;
      text-align: unset;
    }
    .title::before {
      content: "";
      display: block;
    }
  }

  .actions {
    flex-direction: row;
  }

  .section2 {
    margin: 100px 0 0 0;
    .cards {
      padding: 40px 80px 40px 80px;
    }
    .card {
      flex: 0 0 300px;
      height: 426px;
      border-radius: 27px;

      &__video {
        position: absolute;
        width: 300px;
        height: 426px
      }
    }
  }

  .info {
    li {
        font-size: 32px;
        line-height: 50px;
        background-size: 30px;
        padding-left: 35px;
      }

    &__inner {
      position: absolute;
      justify-content: unset;
      aspect-ratio: unset;
      margin-bottom: 0;
      margin-top: calc(10vw - 100px);
    }
    .bubbles {
      font-size: 20px;
      border-radius: 40px;
      padding: 20px 32px;
    }
    .infographics {
      width: 50%;
      gap: 0;
    }
    .background-video::after {
      content: "";
      background: linear-gradient(
        180deg, 
        rgb(0, 0, 0) 0%, 
        rgba(0, 0, 0, 0.3) 15%, 
        rgba(0, 0, 0, 0.3) 85%, 
        rgb(0, 0, 0) 100%);
    }
    .vid_container {
      margin-top: 50px;
    }
  }

  .footer {
    &__inner {
      flex-direction: row;
    }
    &__items {
      display: flex;
      flex-direction: row;
      align-self: unset;
      margin: 0;
    }
    &__info {
      align-self: unset;
    }
  }
}