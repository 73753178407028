.stripe {
    width: 50%;
    
    .form-row {
        height: 50px;
        margin-top: 5px;

        input {
            padding: 0 10px 0 10px;
            width: -webkit-fill-available;
        }
    }

    .stripe-row{
        margin: 30px 0 50px 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        align-self: center;
        color: #ffffff;
        max-width: 800px;

        #card-element {
            background: rgb(30, 34, 46);
            border-radius: 4px;
            margin-top: 20px;
            padding: 16px;
        }
    }

    .form-input {
        height: 50px;
        width: 100%;
        background: rgb(30, 34, 46);
        border: none;
        color: #d1d1d1;
        border-radius: 4px;
    }

    form {
        width: 100%;
        text-align: center;
    }
}


@media only screen and (min-width : 320px) {
    .stripe {
        width: 100%;
    }
}
@media only screen and (min-width : 1200px) { 
    .stripe {
        width: 50%;
    }
}