.reviews {
    height: 300px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 10%,
      rgba(57, 104, 131, 0.5) 55%,
      rgba(0, 0, 0, 1) 100%
    );
    &__title {
      margin-top: 90px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      color: #ffffff;
      position: relative;
      width: fit-content;
      z-index: 1;
    }
    &__title::after {
      content: "";
      display: block;
      width: 254px;
      height: 1px;
      background: #f8f8f8;
      right: -284px;
      top: 50%;
      position: absolute;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 180px;

        .review {
            display: flex;
            flex-direction: column;
            margin: 20px;

            &__author {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: lighter;
                font-size: 20px;
                line-height: 29px;
                text-align: center;
                margin: 10px;
                color: #ffffff;
            }

            &__title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
            
                color: #ffffff;
            }
        }
    }

    .top-cover {
        height: 400px;
        width: 100%;
        z-index: 1;
        position: absolute;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 20%,
            rgba(57, 104, 131, 0.0) 60%,
            rgba(0, 0, 0, 1) 100%
        );
    }
}


@media only screen and (min-width : 320px) {
    .reviews__inner{
        .review__author {
            font-size: 12px;
            line-height: 16px;
        }
        .review__title {
            font-size: 14px;
            line-height: 18px;
        }
    } 
  }
  
  @media only screen and (min-width : 1200px) {      
    .reviews__inner{
        .review__author {
            font-size: 20px;
            line-height: 29px;
        }
        .review__title {
            font-size: 24px;
            line-height: 29px;
        }
    } 
  }
