.header {
  background: linear-gradient(180deg, #000000 19.79%, rgba(0, 0, 0, 0) 100%);
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  transition: 200ms;

  &__logo {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .nav {
    display: flex;
    gap: 35px;
    align-items: center;
    flex-direction: row;

    &__link {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .logo_menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .menu_icon {
        display: block;
        width: 40px;
        height: 40px;
        align-self: center;
        right: 2vw;
        cursor: pointer;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 35px;
  }
}

.experience_container::-webkit-scrollbar {
  display: none;
}

.experience_container {
  width: auto;
  overflow: auto;

  .cards {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 50px;
    gap: 50px;
  
    .card {
      width: 200px;
      height: 281px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 15px 5px;
  
      &__title {
        height: 75px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100px;
        padding-left: 24px;
        color: #ffffff;
        background: linear-gradient(
          180deg,
          rgba(22, 40, 51, 0) 0%,
          rgba(14, 31, 48, 0.9) 50%,
          rgba(14, 31, 48, 1) 100%
        );
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  background: linear-gradient(180deg, #000000 19.79%, rgba(27, 61, 92, 0.75) 100%);

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    height: 100%;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    align-self: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    color: #ffffff;
    position: relative;
    width: fit-content;
    text-transform: uppercase;
  }
  
  &__body {
    display: flex;
    width: auto;
    height: 400px;
    margin-top: 50px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 27px;
    text-transform: uppercase;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    color: rgb(45, 50, 66);
    background-image: url("../assets/img/world.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 300px;

    .country {
      &__title {
        font-style: normal;
        font-weight: bold;
      }

      &__body {
        margin-top: 20px;
        margin-left: 30px;
        line-height: 30px;
        font-size: 16px;

        li {
          text-decoration: underline 1px;
          text-decoration-color: transparent;
          transition: 300ms;
        }
        
        li:hover{
          cursor: pointer;
          text-decoration-color: rgb(45, 50, 66);
        }
      }
    }
  }
}
.battleground_logo{
  width: 60px;
  margin-top: 5px;
  height: auto;
}

@media only screen and (min-width : 300px) {  
  .header {
    overflow: hidden;
    position: fixed;
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
    }

    #header_btn{
      display: none;
    }

    .nav {
      flex-direction: column;
      width: 100%;

      &__link {
        margin-top: 20px;
      }

      .logo_menu {
        width: 100%;
        padding: 10px;

        .menu_icon {
          display: block;
        }
      }
    }
  
    nav {
      flex-direction: column;
    }
  }

  .experience_container .cards {
    display: grid;
    grid-template-columns: auto;
    margin: 50px;
    gap: 50px;
    justify-content: center;
  }
  .modal {
    &__title {
      font-size: 30px;
    }
  }
}

@media only screen and (min-width : 1200px) {
  .battleground_logo{
    width: 130px;
    margin-top: 20px;
    height: auto;
    position: relative;
  }
  .header {
    position: absolute;
    background: linear-gradient(180deg, #000000 19.79%, rgba(0, 0, 0, 0) 100%);

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 11vh;
    }
    
    #header_btn{
      display: block;
    }

    .nav {
      flex-direction: row;
      width: auto;

      &__link {
        margin-top: 0;
      }
      
      .logo_menu {
        width: auto;
        padding: 0;

        .menu_icon {
          display: none;
        }
      }
    }
  
    nav {
      flex-direction: row;
    }
  }

  .experience_container .cards {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 50px;
    gap: 50px;
    justify-content: center;
  }

  .modal {
    &__title {
      font-size: 48px;
    }
  }
}