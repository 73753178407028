.faq {
    min-height: 100vh;

    &__body{
        margin: 300px 0 0 0;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        z-index: 0;
        color: #ffffff;
    }

    .questions {
        margin-top: 50px;
        height: max-content;
        display: flex;
        flex-direction: column;

        .arrow_down {
            position: absolute;
            width: 20px;
            height: 20px;
            margin: 30px 20px 0 0;
            justify-self: right;
            background: url("../assets/img/arrow-down.png");
            background-size: contain;
            background-repeat: no-repeat;
            pointer-events: none;
        }
    }

    &__question {
        display:grid;
        align-self: center;
        max-width: 800px;
        margin: 5px 0 5px 0;
        padding: 20px;
        background: rgb(30, 34, 46);
        border-radius: 4px;
        height: fit-content;
    }
    
    .description_container{
        overflow: hidden;
        max-height: 0px;
        transition: 400ms;
    }

    &__description {
        padding-top: 20px;
        height: fit-content;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        list-style-position: outside;
        
        li {
            margin-left: 30px;
        }
    }

    .main_background {
      position: absolute;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: -1;
      margin: 0;
      -webkit-mask-image: linear-gradient(
        180deg,
        rgba(57, 104, 131, 1) 75%,
        rgba(45, 50, 66, 0) 95%
      );
  
      img {
        width: 100%;
      }
    }
    .main {
      position: relative;
      min-height: 700px;
      height: 100%;
  
      &__inner {
        padding-top: 156px;
        display: flex;
        justify-content: space-between;
  
        .title {
          margin-top: 0;
          max-width: 833px;
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 96px;
          line-height: 117px;
          z-index: 0;
  
          color: #ffffff;
        }
      }
    }
  }
  
@media only screen and (min-width : 320px) {
  .faq {
    &__body {
      font-size: 16px;
      line-height: 28px;
    }
    .main {
    min-height: 0;
    height: 100%;
    }
  }
}
@media only screen and (min-width : 1200px) { 
  .faq {
    &__body {
      font-size: 24px;
      line-height: 36px;
    }
    .main {
      min-height: 700px;
      height: 100%;
    }
  }
}