.event {
  min-height: 100vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  .cover {
    background: url(../assets/img/event.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .halloween_video {
    -webkit-mask-image: linear-gradient(
      180deg,
      rgba(45, 50, 66, 1) 0%,
      rgba(45, 50, 66, 1) 20%,
      rgba(45, 50, 66, 0.9) 80%,
      rgba(45, 50, 66, 0) 95%
    );
  }
  .main {
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(45, 50, 66, 0.5) 20%,
      rgba(45, 50, 66, 0.3) 100%
    );

    &__inner {
      display: flex;
      justify-content: space-between;
    }

    &__form {
      width: 400px;
      background-color: rgba(0, 0, 0, 0);
      padding: 32px;
      border-radius: 32px;
      // border: #fff solid 1px;

      &--title {
        color: #fff;
        font-size: 21px;
        padding-bottom: 8px;
        font-weight: 700;
      }
      &--label {
        font-size: 16px;
        color: #fff;
      }
      &--label {
        font-size: 16px;
        color: #fff;
      }

      &--group {
        row-gap: 16px;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
      }

      &--group.small {
        gap: 16px;
        grid-template-columns: auto auto;
        padding-bottom: 16px;
      }

      &--input {
        width: calc(100% - 8px);
        padding-left: 8px;
        height: 50px;
        border: none;
        color: #191c24;
        border-radius: 4px;
        font-family: "Montserrat";
      }
      input[type="text"] {
        background-color: #fff;
      }

      &--actions {
        display: flex;
        justify-content: flex-end;
      }

      &--btn {
      }
      &--btn:disabled {
        background: #3b080a;
        cursor:no-drop;
      }
    }

    .subtitle {
      font-size: 16px;
      color: #fff;
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .actions {
        padding-bottom: 32px;
      }
    }
  }
  .event_description{
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .experience {

    margin: 20vh 0 10vh 0;

    &__inner {
      min-height: 600px;

      .text {
        padding-top: 128px;
        max-width: 736px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;

        color: #ffffff;
      }
    }
  }

  .event_secondback {
    position: absolute;
    left: 0;
    img {
      width: 100%;
    }
    -webkit-mask-image: linear-gradient(
      180deg,
      rgba(45, 50, 66, 0) 0%,
      rgba(45, 50, 66, 0.8) 20%,
      rgba(45, 50, 66, 0.9) 80%,
      rgba(45, 50, 66, 0) 95%
    );
  }

  .visualization {
    &__inner {
      display: grid;
      justify-content: center;
      grid-template-columns: 400px 400px 400px;
      grid-template-rows: 300px 300px 300px;
      gap: 32px;
      padding-bottom: 64px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-color: rgb(30, 34, 46);
      border-radius: 2vw;
      padding: 16px;
      box-shadow: rgba(39, 61, 121, 0.4) 0px 0px 15px 5px;
      font-family: "Montserrat";
      color: #ffffff;

      // &:hover {
      //   border: #000 solid 1px;
      // }
      // &:hover {
      //   .visualization__item--title {
      //     display: none;
      //   }
      // } 
      &.item1 {
        background: url(../assets/img/event/i1.webp)  center no-repeat;
        background-size: cover;
        
      }
      &.item2 {
        background: url(../assets/img/event/i2.webp)  center no-repeat;
        background-size: cover;
      }
      &.item3 {
        background: url(../assets/img/event/i3.webp)  center no-repeat;
        background-size: cover;
      }
      &.item4 {
        background: url(../assets/img/event.webp)  center no-repeat;
        background-size: cover;
      }
      &.item5 {
        background: url(../assets/img/event/i5.webp)  center no-repeat;
        background-size: cover;
      }
      &.item6 {
        background: url(../assets/img/event/i6.webp)  center no-repeat;
        background-size: cover;
      }
      &.item7 {
        background: url(../assets/img/event/i7.webp)  center no-repeat;
        background-size: cover;
      }
      &.item8 {
        background: url(../assets/img/event/i8.webp)  center no-repeat;
        background-size: cover;
      }
      &.item9 {
        background: url(../assets/img/event/i9.JPG)  center no-repeat;
        background-size: cover;
      }

      //card background for los-angeles below
      &.losItem1 {
        background: url(../assets/img/event/los-angeles/freeroom.webp)  center no-repeat;
        background-size: cover;
        
      }
      &.losItem2 {
        background: url(../assets/img/event/los-angeles/rocket.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem3 {
        background: url(../assets/img/event/i3.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem4 {
        background: url(../assets/img/event/los-angeles/party.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem5 {
        background: url(../assets/img/event/los-angeles/roller.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem6 {
        background: url(../assets/img/event/los-angeles/karaoke.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem7 {
        background: url(../assets/img/event/i8.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem8 {
        background: url(../assets/img/event/los-angeles/station.webp)  center no-repeat;
        background-size: cover;
      }
      &.losItem9 {
        background: url(../assets/img/event/i6.webp)  center no-repeat;
        background-size: cover;
      }

      &--title {
        text-align: center;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        font-weight: 700;
        color: #fff;
        padding-bottom: 10px;
        align-self: flex-end;
      }
    }

    &__item::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) -15%,
        rgba(12, 88, 139, 0.8) 15%,
        rgba(45, 50, 66, 0) 55%
      );
      z-index: -1;
    }
  }

  .packages {
    &__inner {
      display: grid;
      margin-top: 100px;
      margin-bottom: 50px;
      grid-template-columns: repeat(4, auto);
      grid-template-rows: auto;
      justify-content: center;
      gap: 32px;
    }

    .discount-title {
      margin: 50px auto;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      font-size: 34px;
      color: #ffffff;
      position: relative;
      width: -moz-fit-content;
      width: fit-content;
      z-index: 1;
    }
  }

  .questions {
    margin: 32px auto;
    &__inner {
      display: flex;
      justify-content: center;
    }
  }

  .video {
    padding-bottom: 64px;
    padding-top: 64px;
    &__inner {
      .main-video {
        
      }
    }
  }
}

@media only screen and (min-width : 320px) {
  .event {
    .experience {
      margin: 10vh 0 10vh 0;
      height: 20vh;
      &__inner {
        min-height: 100px;
        display: flex;
      }
    }
    .description_text {
      max-width: 316px;
      align-self: center;
    }
    .main {
      height: fit-content;
    }
    .main__inner {
      flex-direction: column;
    }
    .main__right {
      margin-top: 100px;
    }
    .main__form {
      width: auto;
      padding: 0;
    }

    .visualization__inner {
      display: grid;
      grid-template-columns: 150px 150px;
      grid-template-rows: max-content;
      justify-content: center;
      gap: 16px;
    }
    .visualization__item--title {
      font-size: 12px;
      padding-bottom: 0;
    }

    .visualization__item {
      height: 100px;
    }
    .packages{
      .discount-title {
        margin: 20px auto;
        font-size: 16px;
      }

      &__inner {
        display: grid;
        margin-top: 20px;
        margin-bottom: 50px;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: auto;
        justify-content: center;
        gap: 32px;
      }
    }
  }
}

@media only screen and (min-width : 1200px) { 
  .event {
    .experience {
      margin: 20vh 0 0 0;
      height: 80vh;
      &__inner {
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .description_text {
      max-width: 1114px;
      align-self: center;
    }
    .main {
      height: 100vh;
    }
    .main__inner {
      flex-direction: unset;
    }
    .main__right {
      margin-top: unset;
    }
    .main__form {
      width: 400px;
      padding: 32px;
    }

    .visualization__inner {
      display: grid;
      justify-content: center;
      grid-template-columns: 350px 350px 350px;
      grid-template-rows: 250px 250px 250px;
      gap: 32px;
    }
    .visualization__item--title {
      font-size: 21px;
      padding-bottom: 10px;
    }
    .visualization__item {
      height: unset;
    }

    .packages{
      .discount-title {
        margin: 50px auto;
        font-size: 34px;
      }

      &__inner {
        display: grid;
        margin-top: 50px;
        margin-bottom: 50px;
        grid-template-columns: repeat(4, auto);
        grid-template-rows: auto;
        justify-content: center;
        gap: 32px;
      }
    }
    
  }
}
